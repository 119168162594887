var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "h4 m-0"
  }, [_vm._v("89.9%")]), _c('div', [_vm._v("Lorem ipsum...")]), _c('b-progress', {
    staticClass: "progress-xs my-3",
    attrs: {
      "height": "{}",
      "variant": "success",
      "value": 25
    }
  }), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lorem ipsum dolor sit amet enim.")])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "h4 m-0"
  }, [_vm._v("12.124")]), _c('div', [_vm._v("Lorem ipsum...")]), _c('b-progress', {
    staticClass: "progress-xs my-3",
    attrs: {
      "height": "{}",
      "variant": "info",
      "value": 25
    }
  }), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lorem ipsum dolor sit amet enim.")])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "h4 m-0"
  }, [_vm._v("$98.111,00")]), _c('div', [_vm._v("Lorem ipsum...")]), _c('b-progress', {
    staticClass: "progress-xs my-3",
    attrs: {
      "height": "{}",
      "variant": "warning",
      "value": 25
    }
  }), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lorem ipsum dolor sit amet enim.")])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "h4 m-0"
  }, [_vm._v("2 TB")]), _c('div', [_vm._v("Lorem ipsum...")]), _c('b-progress', {
    staticClass: "progress-xs my-3",
    attrs: {
      "height": "{}",
      "variant": "danger",
      "value": 25
    }
  }), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lorem ipsum dolor sit amet enim.")])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    staticClass: "text-white bg-primary"
  }, [_c('div', {
    staticClass: "h4 m-0"
  }, [_vm._v("89.9%")]), _c('div', [_vm._v("Lorem ipsum...")]), _c('b-progress', {
    staticClass: "progress-white progress-xs my-3",
    attrs: {
      "height": "{}",
      "value": 25
    }
  }), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lorem ipsum dolor sit amet enim.")])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    staticClass: "text-white bg-warning"
  }, [_c('div', {
    staticClass: "h4 m-0"
  }, [_vm._v("12.124")]), _c('div', [_vm._v("Lorem ipsum...")]), _c('b-progress', {
    staticClass: "progress-white progress-xs my-3",
    attrs: {
      "height": "{}",
      "value": 25
    }
  }), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lorem ipsum dolor sit amet enim.")])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    staticClass: "text-white bg-danger"
  }, [_c('div', {
    staticClass: "h4 m-0"
  }, [_vm._v("$98.111,00")]), _c('div', [_vm._v("Lorem ipsum...")]), _c('b-progress', {
    staticClass: "progress-white progress-xs my-3",
    attrs: {
      "height": "{}",
      "value": 25
    }
  }), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lorem ipsum dolor sit amet enim.")])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    staticClass: "text-white bg-info"
  }, [_c('div', {
    staticClass: "h4 m-0"
  }, [_vm._v("2 TB")]), _c('div', [_vm._v("Lorem ipsum...")]), _c('b-progress', {
    staticClass: "progress-white progress-xs my-3",
    attrs: {
      "height": "{}",
      "value": 25
    }
  }), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lorem ipsum dolor sit amet enim.")])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true
    }
  }, [_c('b-card-body', {
    staticClass: "p-3 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-cogs bg-primary p-3 font-2xl mr-3 float-left"
  }), _c('div', {
    staticClass: "h5 text-primary mb-0 mt-2"
  }, [_vm._v("$1.999,50")]), _c('div', {
    staticClass: "text-muted text-uppercase font-weight-bold font-xs"
  }, [_vm._v("Income")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true
    }
  }, [_c('b-card-body', {
    staticClass: "p-3 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-laptop bg-info p-3 font-2xl mr-3 float-left"
  }), _c('div', {
    staticClass: "h5 text-info mb-0 mt-2"
  }, [_vm._v("$1.999,50")]), _c('div', {
    staticClass: "text-muted text-uppercase font-weight-bold font-xs"
  }, [_vm._v("Income")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true
    }
  }, [_c('b-card-body', {
    staticClass: "p-3 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-moon-o bg-warning p-3 font-2xl mr-3 float-left"
  }), _c('div', {
    staticClass: "h5 text-warning mb-0 mt-2"
  }, [_vm._v("$1.999,50")]), _c('div', {
    staticClass: "text-muted text-uppercase font-weight-bold font-xs"
  }, [_vm._v("Income")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true
    }
  }, [_c('b-card-body', {
    staticClass: "p-3 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-bell bg-danger p-3 font-2xl mr-3 float-left"
  }), _c('div', {
    staticClass: "h5 text-danger mb-0 mt-2"
  }, [_vm._v("$1.999,50")]), _c('div', {
    staticClass: "text-muted text-uppercase font-weight-bold font-xs"
  }, [_vm._v("Income")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true,
      "footer-class": "px-3 py-2"
    }
  }, [_c('b-card-body', {
    staticClass: "p-3 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-cogs bg-primary p-3 font-2xl mr-3 float-left"
  }), _c('div', {
    staticClass: "h5 text-primary mb-0 mt-2"
  }, [_vm._v("$1.999,50")]), _c('div', {
    staticClass: "text-muted text-uppercase font-weight-bold font-xs"
  }, [_vm._v("Income")])]), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('b-link', {
    staticClass: "font-weight-bold font-xs btn-block text-muted",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("View More "), _c('i', {
    staticClass: "fa fa-angle-right float-right font-lg"
  })])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true,
      "footer-class": "px-3 py-2"
    }
  }, [_c('b-card-body', {
    staticClass: "p-3 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-laptop bg-info p-3 font-2xl mr-3 float-left"
  }), _c('div', {
    staticClass: "h5 text-info mb-0 mt-2"
  }, [_vm._v("$1.999,50")]), _c('div', {
    staticClass: "text-muted text-uppercase font-weight-bold font-xs"
  }, [_vm._v("Income")])]), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('b-link', {
    staticClass: "font-weight-bold font-xs btn-block text-muted",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("View More "), _c('i', {
    staticClass: "fa fa-angle-right float-right font-lg"
  })])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true,
      "footer-class": "px-3 py-2"
    }
  }, [_c('b-card-body', {
    staticClass: "p-3 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-moon-o bg-warning p-3 font-2xl mr-3 float-left"
  }), _c('div', {
    staticClass: "h5 text-warning mb-0 mt-2"
  }, [_vm._v("$1.999,50")]), _c('div', {
    staticClass: "text-muted text-uppercase font-weight-bold font-xs"
  }, [_vm._v("Income")])]), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('b-link', {
    staticClass: "font-weight-bold font-xs btn-block text-muted",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("View More "), _c('i', {
    staticClass: "fa fa-angle-right float-right font-lg"
  })])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true,
      "footer-class": "px-3 py-2"
    }
  }, [_c('b-card-body', {
    staticClass: "p-3 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-bell bg-danger p-3 font-2xl mr-3 float-left"
  }), _c('div', {
    staticClass: "h5 text-danger mb-0 mt-2"
  }, [_vm._v("$1.999,50")]), _c('div', {
    staticClass: "text-muted text-uppercase font-weight-bold font-xs"
  }, [_vm._v("Income")])]), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('b-link', {
    staticClass: "font-weight-bold font-xs btn-block text-muted",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("View More "), _c('i', {
    staticClass: "fa fa-angle-right float-right font-lg"
  })])], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true
    }
  }, [_c('b-card-body', {
    staticClass: "p-0 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-cogs bg-primary p-4 font-2xl mr-3 float-left"
  }), _c('div', {
    staticClass: "h5 text-primary mb-0 pt-3"
  }, [_vm._v("$1.999,50")]), _c('div', {
    staticClass: "text-muted text-uppercase font-weight-bold font-xs"
  }, [_vm._v("Income")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true
    }
  }, [_c('b-card-body', {
    staticClass: "p-0 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-laptop bg-info p-4 font-2xl mr-3 float-left"
  }), _c('div', {
    staticClass: "h5 text-info mb-0 pt-3"
  }, [_vm._v("$1.999,50")]), _c('div', {
    staticClass: "text-muted text-uppercase font-weight-bold font-xs"
  }, [_vm._v("Income")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true
    }
  }, [_c('b-card-body', {
    staticClass: "p-0 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-moon-o bg-warning p-4 font-2xl mr-3 float-left"
  }), _c('div', {
    staticClass: "h5 text-warning mb-0 pt-3"
  }, [_vm._v("$1.999,50")]), _c('div', {
    staticClass: "text-muted text-uppercase font-weight-bold font-xs"
  }, [_vm._v("Income")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true
    }
  }, [_c('b-card-body', {
    staticClass: "p-0 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-bell bg-danger p-4 font-2xl mr-3 float-left"
  }), _c('div', {
    staticClass: "h5 text-danger mb-0 pt-3"
  }, [_vm._v("$1.999,50")]), _c('div', {
    staticClass: "text-muted text-uppercase font-weight-bold font-xs"
  }, [_vm._v("Income")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true
    }
  }, [_c('b-card-body', {
    staticClass: "p-0 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-cogs bg-primary p-4 px-5 font-2xl mr-3 float-left"
  }), _c('div', {
    staticClass: "h5 text-primary mb-0 pt-3"
  }, [_vm._v("$1.999,50")]), _c('div', {
    staticClass: "text-muted text-uppercase font-weight-bold font-xs"
  }, [_vm._v("Income")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true
    }
  }, [_c('b-card-body', {
    staticClass: "p-0 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-laptop bg-info p-4 px-5 font-2xl mr-3 float-left"
  }), _c('div', {
    staticClass: "h5 text-info mb-0 pt-3"
  }, [_vm._v("$1.999,50")]), _c('div', {
    staticClass: "text-muted text-uppercase font-weight-bold font-xs"
  }, [_vm._v("Income")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true
    }
  }, [_c('b-card-body', {
    staticClass: "p-0 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-moon-o bg-warning p-4 px-5 font-2xl mr-3 float-left"
  }), _c('div', {
    staticClass: "h5 text-warning mb-0 pt-3"
  }, [_vm._v("$1.999,50")]), _c('div', {
    staticClass: "text-muted text-uppercase font-weight-bold font-xs"
  }, [_vm._v("Income")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": true
    }
  }, [_c('b-card-body', {
    staticClass: "p-0 clearfix"
  }, [_c('i', {
    staticClass: "fa fa-bell bg-danger p-4 px-5 font-2xl mr-3 float-left"
  }), _c('div', {
    staticClass: "h5 text-danger mb-0 pt-3"
  }, [_vm._v("$1.999,50")]), _c('div', {
    staticClass: "text-muted text-uppercase font-weight-bold font-xs"
  }, [_vm._v("Income")])])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "brand-card"
  }, [_c('div', {
    staticClass: "brand-card-header bg-facebook"
  }, [_c('i', {
    staticClass: "fa fa-facebook"
  }), _c('div', {
    staticClass: "chart-wrapper"
  }, [_c('social-box-chart-example', {
    attrs: {
      "data": [65, 59, 84, 84, 51, 55, 40]
    }
  })], 1)]), _c('div', {
    staticClass: "brand-card-body"
  }, [_c('div', [_c('div', {
    staticClass: "text-value"
  }, [_vm._v("89k")]), _c('div', {
    staticClass: "text-uppercase text-muted small"
  }, [_vm._v("friends")])]), _c('div', [_c('div', {
    staticClass: "text-value"
  }, [_vm._v("459")]), _c('div', {
    staticClass: "text-uppercase text-muted small"
  }, [_vm._v("feeds")])])])])]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "brand-card"
  }, [_c('div', {
    staticClass: "brand-card-header bg-twitter"
  }, [_c('i', {
    staticClass: "fa fa-twitter"
  }), _c('div', {
    staticClass: "chart-wrapper"
  }, [_c('social-box-chart-example', {
    attrs: {
      "data": [1, 13, 9, 17, 34, 41, 38]
    }
  })], 1)]), _c('div', {
    staticClass: "brand-card-body"
  }, [_c('div', [_c('div', {
    staticClass: "text-value"
  }, [_vm._v("973k")]), _c('div', {
    staticClass: "text-uppercase text-muted small"
  }, [_vm._v("followers")])]), _c('div', [_c('div', {
    staticClass: "text-value"
  }, [_vm._v("1.792")]), _c('div', {
    staticClass: "text-uppercase text-muted small"
  }, [_vm._v("tweets")])])])])]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "brand-card"
  }, [_c('div', {
    staticClass: "brand-card-header bg-linkedin"
  }, [_c('i', {
    staticClass: "fa fa-linkedin"
  }), _c('div', {
    staticClass: "chart-wrapper"
  }, [_c('social-box-chart-example', {
    attrs: {
      "data": [78, 81, 80, 45, 34, 12, 40]
    }
  })], 1)]), _c('div', {
    staticClass: "brand-card-body"
  }, [_c('div', [_c('div', {
    staticClass: "text-value"
  }, [_vm._v("500+")]), _c('div', {
    staticClass: "text-uppercase text-muted small"
  }, [_vm._v("contacts")])]), _c('div', [_c('div', {
    staticClass: "text-value"
  }, [_vm._v("292")]), _c('div', {
    staticClass: "text-uppercase text-muted small"
  }, [_vm._v("feeds")])])])])]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "brand-card"
  }, [_c('div', {
    staticClass: "brand-card-header bg-google-plus"
  }, [_c('i', {
    staticClass: "fa fa-google-plus"
  }), _c('div', {
    staticClass: "chart-wrapper"
  }, [_c('social-box-chart-example', {
    attrs: {
      "data": [35, 23, 56, 22, 97, 23, 64]
    }
  })], 1)]), _c('div', {
    staticClass: "brand-card-body"
  }, [_c('div', [_c('div', {
    staticClass: "text-value"
  }, [_vm._v("894")]), _c('div', {
    staticClass: "text-uppercase text-muted small"
  }, [_vm._v("followers")])]), _c('div', [_c('div', {
    staticClass: "text-value"
  }, [_vm._v("92")]), _c('div', {
    staticClass: "text-uppercase text-muted small"
  }, [_vm._v("circles")])])])])])], 1), _c('b-card-group', {
    staticClass: "mb-4"
  }, [_c('b-card', [_c('div', {
    staticClass: "h1 text-muted text-right mb-4"
  }, [_c('i', {
    staticClass: "icon-people"
  })]), _c('div', {
    staticClass: "h4 mb-0"
  }, [_vm._v("87.500")]), _c('small', {
    staticClass: "text-muted text-uppercase font-weight-bold"
  }, [_vm._v("Visitors")]), _c('b-progress', {
    staticClass: "progress-xs mt-3 mb-0",
    attrs: {
      "height": "{}",
      "variant": "info",
      "value": 25
    }
  })], 1), _c('b-card', [_c('div', {
    staticClass: "h1 text-muted text-right mb-4"
  }, [_c('i', {
    staticClass: "icon-user-follow"
  })]), _c('div', {
    staticClass: "h4 mb-0"
  }, [_vm._v("385")]), _c('small', {
    staticClass: "text-muted text-uppercase font-weight-bold"
  }, [_vm._v("New Clients")]), _c('b-progress', {
    staticClass: "progress-xs mt-3 mb-0",
    attrs: {
      "height": "{}",
      "variant": "success",
      "value": 25
    }
  })], 1), _c('b-card', [_c('div', {
    staticClass: "h1 text-muted text-right mb-4"
  }, [_c('i', {
    staticClass: "icon-basket-loaded"
  })]), _c('div', {
    staticClass: "h4 mb-0"
  }, [_vm._v("1238")]), _c('small', {
    staticClass: "text-muted text-uppercase font-weight-bold"
  }, [_vm._v("Products sold")]), _c('b-progress', {
    staticClass: "progress-xs mt-3 mb-0",
    attrs: {
      "height": "{}",
      "variant": "warning",
      "value": 25
    }
  })], 1), _c('b-card', [_c('div', {
    staticClass: "h1 text-muted text-right mb-4"
  }, [_c('i', {
    staticClass: "icon-pie-chart"
  })]), _c('div', {
    staticClass: "h4 mb-0"
  }, [_vm._v("28%")]), _c('small', {
    staticClass: "text-muted text-uppercase font-weight-bold"
  }, [_vm._v("Returning Visitors")]), _c('b-progress', {
    staticClass: "progress-xs mt-3 mb-0",
    attrs: {
      "height": "{}",
      "value": 25
    }
  })], 1), _c('b-card', [_c('div', {
    staticClass: "h1 text-muted text-right mb-4"
  }, [_c('i', {
    staticClass: "icon-speedometer"
  })]), _c('div', {
    staticClass: "h4 mb-0"
  }, [_vm._v("5:34:11")]), _c('small', {
    staticClass: "text-muted text-uppercase font-weight-bold"
  }, [_vm._v("Avg. Time")]), _c('b-progress', {
    staticClass: "progress-xs mt-3 mb-0",
    attrs: {
      "height": "{}",
      "variant": "danger",
      "value": 25
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "6",
      "md": "2"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "h1 text-muted text-right mb-4"
  }, [_c('i', {
    staticClass: "icon-people"
  })]), _c('div', {
    staticClass: "h4 mb-0"
  }, [_vm._v("87.500")]), _c('small', {
    staticClass: "text-muted text-uppercase font-weight-bold"
  }, [_vm._v("Visitors")]), _c('b-progress', {
    staticClass: "progress-xs mt-3 mb-0",
    attrs: {
      "height": "{}",
      "variant": "info",
      "value": 25
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "2"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "h1 text-muted text-right mb-4"
  }, [_c('i', {
    staticClass: "icon-user-follow"
  })]), _c('div', {
    staticClass: "h4 mb-0"
  }, [_vm._v("385")]), _c('small', {
    staticClass: "text-muted text-uppercase font-weight-bold"
  }, [_vm._v("New Clients")]), _c('b-progress', {
    staticClass: "progress-xs mt-3 mb-0",
    attrs: {
      "height": "{}",
      "variant": "success",
      "value": 25
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "2"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "h1 text-muted text-right mb-4"
  }, [_c('i', {
    staticClass: "icon-basket-loaded"
  })]), _c('div', {
    staticClass: "h4 mb-0"
  }, [_vm._v("1238")]), _c('small', {
    staticClass: "text-muted text-uppercase font-weight-bold"
  }, [_vm._v("Products sold")]), _c('b-progress', {
    staticClass: "progress-xs mt-3 mb-0",
    attrs: {
      "height": "{}",
      "variant": "warning",
      "value": 25
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "2"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "h1 text-muted text-right mb-4"
  }, [_c('i', {
    staticClass: "icon-pie-chart"
  })]), _c('div', {
    staticClass: "h4 mb-0"
  }, [_vm._v("28%")]), _c('small', {
    staticClass: "text-muted text-uppercase font-weight-bold"
  }, [_vm._v("Returning Visitors")]), _c('b-progress', {
    staticClass: "progress-xs mt-3 mb-0",
    attrs: {
      "height": "{}",
      "value": 25
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "2"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "h1 text-muted text-right mb-4"
  }, [_c('i', {
    staticClass: "icon-speedometer"
  })]), _c('div', {
    staticClass: "h4 mb-0"
  }, [_vm._v("5:34:11")]), _c('small', {
    staticClass: "text-muted text-uppercase font-weight-bold"
  }, [_vm._v("Avg. Time")]), _c('b-progress', {
    staticClass: "progress-xs mt-3 mb-0",
    attrs: {
      "height": "{}",
      "variant": "danger",
      "value": 25
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "2"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "h1 text-muted text-right mb-4"
  }, [_c('i', {
    staticClass: "icon-speech"
  })]), _c('div', {
    staticClass: "h4 mb-0"
  }, [_vm._v("972")]), _c('small', {
    staticClass: "text-muted text-uppercase font-weight-bold"
  }, [_vm._v("Comments")]), _c('b-progress', {
    staticClass: "progress-xs mt-3 mb-0",
    attrs: {
      "height": "{}",
      "variant": "info",
      "value": 25
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "6",
      "md": "2"
    }
  }, [_c('b-card', {
    staticClass: "text-white bg-info"
  }, [_c('div', {
    staticClass: "h1 text-muted text-right mb-4"
  }, [_c('i', {
    staticClass: "icon-people"
  })]), _c('div', {
    staticClass: "h4 mb-0"
  }, [_vm._v("87.500")]), _c('small', {
    staticClass: "text-muted text-uppercase font-weight-bold"
  }, [_vm._v("Visitors")]), _c('b-progress', {
    staticClass: "progress-white progress-xs mt-3",
    attrs: {
      "height": "{}",
      "value": 25
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "2"
    }
  }, [_c('b-card', {
    staticClass: "text-white bg-success"
  }, [_c('div', {
    staticClass: "h1 text-muted text-right mb-4"
  }, [_c('i', {
    staticClass: "icon-user-follow"
  })]), _c('div', {
    staticClass: "h4 mb-0"
  }, [_vm._v("385")]), _c('small', {
    staticClass: "text-muted text-uppercase font-weight-bold"
  }, [_vm._v("New Clients")]), _c('b-progress', {
    staticClass: "progress-white progress-xs mt-3",
    attrs: {
      "height": "{}",
      "value": 25
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "2"
    }
  }, [_c('b-card', {
    staticClass: "text-white bg-warning"
  }, [_c('div', {
    staticClass: "h1 text-muted text-right mb-4"
  }, [_c('i', {
    staticClass: "icon-basket-loaded"
  })]), _c('div', {
    staticClass: "h4 mb-0"
  }, [_vm._v("1238")]), _c('small', {
    staticClass: "text-muted text-uppercase font-weight-bold"
  }, [_vm._v("Products sold")]), _c('b-progress', {
    staticClass: "progress-white progress-xs mt-3",
    attrs: {
      "height": "{}",
      "value": 25
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "2"
    }
  }, [_c('b-card', {
    staticClass: "text-white bg-primary"
  }, [_c('div', {
    staticClass: "h1 text-muted text-right mb-4"
  }, [_c('i', {
    staticClass: "icon-pie-chart"
  })]), _c('div', {
    staticClass: "h4 mb-0"
  }, [_vm._v("28%")]), _c('small', {
    staticClass: "text-muted text-uppercase font-weight-bold"
  }, [_vm._v("Returning Visitors")]), _c('b-progress', {
    staticClass: "progress-white progress-xs mt-3",
    attrs: {
      "height": "{}",
      "value": 25
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "2"
    }
  }, [_c('b-card', {
    staticClass: "text-white bg-danger"
  }, [_c('div', {
    staticClass: "h1 text-muted text-right mb-4"
  }, [_c('i', {
    staticClass: "icon-speedometer"
  })]), _c('div', {
    staticClass: "h4 mb-0"
  }, [_vm._v("5:34:11")]), _c('small', {
    staticClass: "text-muted text-uppercase font-weight-bold"
  }, [_vm._v("Avg. Time")]), _c('b-progress', {
    staticClass: "progress-white progress-xs mt-3",
    attrs: {
      "height": "{}",
      "value": 25
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "2"
    }
  }, [_c('b-card', {
    staticClass: "text-white bg-info"
  }, [_c('div', {
    staticClass: "h1 text-muted text-right mb-4"
  }, [_c('i', {
    staticClass: "icon-speech"
  })]), _c('div', {
    staticClass: "h4 mb-0"
  }, [_vm._v("972")]), _c('small', {
    staticClass: "text-muted text-uppercase font-weight-bold"
  }, [_vm._v("Comments")]), _c('b-progress', {
    staticClass: "progress-white progress-xs mt-3",
    attrs: {
      "height": "{}",
      "value": 25
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }